<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号"></el-input>
        </el-form-item>
<!--        <el-form-item label="维修类型">-->
<!--            <el-input v-model="dataForm.cardTypeName" placeholder="维修类型"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="维修项目">-->
<!--          <el-select class="item-choose" v-model="dataForm.status" @change="getDataList" size="small" filterable clearable placeholder="请选择">-->
<!--            <el-option label="请选择" value=""></el-option>-->
<!--            <el-option v-for="item in dictTypeMap.status" :key="item.id" :label="item.name" :value="item.id" ></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="维修厂">-->
<!--            <el-input v-model="dataForm.cardTypeName" placeholder="维修厂"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="事故时间">
          <el-date-picker
            v-model="dataForm.dateRange"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="72vh" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  :data="dataList" border size="small" v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
        <el-table-column width="90" prop="vehiclePlate" label="事故车牌号" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="100" prop="driverName" label="事故驾驶员" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="130"  prop="accidentTime" label="事故时间" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="accidentPlace" label="事故地点" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="accidentDesc" label="事故描述" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="70" prop="accidentNum" label="事故照片" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, [9001])" style="color: blue;cursor:pointer" v-if="scope.row.accidentNum > 0">查看</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column width="70" prop="dutyName" label="责任方" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="handleResult" label="事故处理结果" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="handleExplain" label="处理说明" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column width="130" prop="successTime" label="处理时间" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column width="70" prop="accidentNumber" label="事故次数" :show-overflow-tooltip="true"></el-table-column>
<!--        <el-table-column prop="createName" label="关联维修" :show-overflow-tooltip="true" width="100">-->
<!--        </el-table-column>-->
        <el-table-column fixed="right" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('incident:management:find')" type="text" size="small" @click="addOrUpdateHandle('view', scope.row)">详情</el-button>
              <el-button v-if="newAuth('incident:management:update')" type="text" size="small" @click="addOrUpdateHandle('edit', scope.row)">修改</el-button>
              <el-button v-if="newAuth('incident:management:delete')" type="text" size="small" @click="deleteHandle(scope.row)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import AddOrUpdate from './detail/cardType-detail'
import viewImage from '@/views/commonPop/viewImage'
export default {
  data () {
    return {
      dataForm: {
        vehiclePlate: null,
        dateRange: null
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false
    }
  },
  components: {
    AddOrUpdate,
    viewImage
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    // 查看照片
    viewPhoto (row, typeList) {
      this.$refs.viewPhoto.init(row.id, typeList)
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let dateRange = this.dataForm.dateRange
      let timeParam = {}
      if (dateRange && dateRange.length === 2) {
        timeParam.repairTimeBegin = dateRange[0]
        timeParam.repairTimeEnd = dateRange[1]
      }
      this.$http({
        url: this.$http.adornUrl('/vehicleAccident/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'repairTimeBegin': timeParam.repairTimeBegin,
          'repairTimeEnd': timeParam.repairTimeEnd
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, row) {
      this.$router.push({
        name: 'incidentPage',
        params: {
          row: row,
          todo: todo
        }
      })
      // this.addOrUpdateVisible = true
      // this.$nextTick(() => {
      //   this.$refs.addOrUpdate.init(todo, id)
      // })
    },
    // 删除
    deleteHandle (row) {
      this.$confirm('确定对【' + row.vehiclePlate + '】进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/vehicleAccident/delete/' + row.id),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
